import { 
    TextBodyLarge, 
    TextDisplayMedium, 
    TextHeadlineMedium 
} from "../common/typography";

const Pricing = ({scrollToTarget}) => {
    
    const plans = [
        {
            name: 'Basic Package',
            price: '$250/20 hrs',
            feature1: 'Unlock Your Potential with Our Basic Package!',
            feature2: 'Hours: 20 hrs',
            feature3: 'Trial: 2 Weeks Free Trial',
            bgColor: 'bg-customColor5',
        },
        {
            name: 'Silver  Package',
            price: '$500/80 hrs',
            feature1: 'Elevate Your Experience with Silver Package!',
            feature2: 'Hours: 80 hrs',
            feature3: 'Trial: 2 Weeks Free Trial',
            bgColor: 'bg-customColor6',
        },
        {
            name: 'Gold  Package',
            price: '$800/160 hrs',
            feature1: 'Maximize Your Success with Gold Package!',
            feature2: 'Hours: 160 hrs',
            feature3: 'Trial: 2 Weeks Free Trial',
            bgColor: 'bg-customColor7',
        }
    ]

    return (
        <div className="bg-primary30 px-4 py-[60px] lg:p-20 w-full flex flex-col justify-center items-center">
            <div className="mb-10 md:mb-20">
                <TextDisplayMedium text={'Our Pricing Packages'} color={'lightOnPrimary'} />
            </div>
            <div className="flex flex-col xl:flex-row justify-center items-center gap-x-6 gap-y-5">
                {
                    plans.map(plan=>(
                        <div className={`${plan.bgColor} rounded-md py-5 px-4 w-full md:w-[392px] md:h-[290px]`}>
                            <div className="text-center  mb-[22px]">
                                <TextHeadlineMedium color={'lighOnSurfaceVariant'} text={plan.name} />
                                <br/>
                                <TextHeadlineMedium color={'lighOnSurfaceVariant'} text={plan.price} />
                            </div>
                            <div className="mb-[40px]">
                                <TextBodyLarge color={'lighOnSurfaceVariant'} text={plan.feature1} />
                                <br/>
                                <TextBodyLarge color={'lighOnSurfaceVariant'} text={plan.feature2} />
                                <br/>
                                <TextBodyLarge color={'lighOnSurfaceVariant'} text={plan.feature3} />
                            </div>
                            <button className="bg-lightPrimaryContainer py-[9px] px-6 font-[500] text-[14px] leading-[20px] text-lightOnPrimaryContainer" onClick={scrollToTarget}>
                                Get Started
                            </button>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};


export default Pricing;
