import { 
    TextDisplayMedium, 
    TextLabelLarge, 
    TextTitleMedium 
} from "../common/typography"

const UserReviews = () => {

    const reviews = [
        {
            review: 'As a transaction coordinator, speed is crucial. Rebillion’s buyer document generator is not only fast but also incredibly accurate. ',
            userRole: 'Transaction Coordinator',
            userName: 'Olivia S.'
        },
        {
            review: 'I’ve tried various tools, but Rebillion stands out for its efficiency and ease of use. My time now goes straight into growing my business.',
            userRole: 'Independent Realtor',
            userName: 'Emma L.'
        },
        {
            review: 'I can create precise, professional documents in minutes, giving my clients confidence that they’re getting the best deal.',
            userRole: 'Real Estate Broker',
            userName: 'James M.'
        },
        {
            review: 'I really like the document summarizer. It cut my workload in half, I no longer dread those long contracts – the summarizer does all the heavy lifting!',
            userRole: 'Real Estate Agent',
            userName: 'Sarah T.'
        }
    ]
    
    return(
        <div className="px-4 lg:px-[108px] py-[60px] lg:py-20 w-full bg-[url('/assets/testimonial-container@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full">

            <div className="text-center">
                <TextDisplayMedium color={'lightOnPrimary'} text={'Loved by real estate professionals'} />
            </div>


            <div className="mt-[60px] w-full flex flex-col lg:flex-row justify-between items-center gap-x-8 gap-y-8">
                {
                    reviews.map(review=>(
                        <div className="bg-lightOnPrimary w-[288px] h-[224px] rounded-lg py-5 px-4 flex flex-col justify-between">
                            <div>
                                <TextLabelLarge color={'lighOnSurfaceVariant'} text={review.review} />
                            </div>
                            <div className="flex flex-row gap-x-3 items-center">
                                <img
                                    className="h-10 w-10"
                                    loading="lazy"
                                    alt=""
                                    src="/assets/background@2x.png"
                                />
                                <div>
                                    <div><TextLabelLarge color={'lighOnSurfaceVariant'} text={review.userRole} /></div>
                                    <TextTitleMedium color={'lightOnSurface'} text={review.userName} />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

        </div>
    )
}

export default UserReviews