import { useRef } from "react";
import SectionTitle from "./sectionTitle";
import Products from "./products";
import WhyChooseUs from "./whyChooseUs";
import Pricing from "./pricing";
import Benefits from "./benefits";
import FitForEveryone from "./fitForEveryone";
import BookDemo from "./bookDemo";
import LetsStart from "./letsStart";
import Footer from "./footer";
import UserReviews from "./userReviews";
import Header from "./header";


const HomePage = () => {

    const targetRef = useRef(null);  // Create a ref object

    const scrollToTarget = () => {
        // Scroll to the ref's current element
        targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <div>
            <Header/>
            <SectionTitle scrollToTarget={scrollToTarget} />
            <Products/>
            <WhyChooseUs/>
            <Pricing scrollToTarget={scrollToTarget}/>
            <Benefits/>
            <UserReviews/>
            <FitForEveryone/>
            <BookDemo targetRef={targetRef}/>
            <LetsStart scrollToTarget={scrollToTarget}/>
            <Footer/>
        </div>
    )
}

export default HomePage;