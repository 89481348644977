import { 
    TextBodyLarge, 
    TextDisplayMedium, 
    TextHeadlineMedium, 
    TextTitleMedium 
} from "../common/typography"

const Products = () => {

    const productsArray = [
        {
            category: 'CONTRACT WRITING',
            title: 'Write Offers drafts, Including Necessary Riders and Addendums',
            description: 'Our human assistants will use advanced AI tools to draft complete offers, riders, and addendums quickly. You’ll get a fully tailored contract that’s reviewed and finalized by a real person to ensure all details are correct and compliant with local regulations.',
            productImage: '/assets/product1.png'   
        },
        {
            category: 'FILE COMPLIANCE & SUMMARIES',
            title: 'Ensure Documents are Signed, Filed, and Compliant with Minimal Effort',
            description: 'Our human assistants will leverage AI tools to track and organize your files, but they will personally review each document to ensure signatures, filings, and compliance are in perfect order. The AI helps with quick checks, while your assistant ensures everything is accurate and properly filed.',
            productImage: '/assets/product2.png'   
        },
        {
            category: 'INBOX MANAGEMENT ',
            title: 'Organize and Prioritize Your Emails for Effective Communication',
            description: 'Our assistant will use AI to sort and prioritize your emails, but they will personally ensure that critical communications are promptly addressed. No more missed emails or delayed responses—our assistants make sure you’re always on top of your inbox.',
            productImage: '/assets/product3.png'   
        },
        {
            category: 'SCHEDULE MANAGEMENT ',
            title: 'Manage Appointments and Avoid Scheduling Conflicts',
            description: 'Our assistants use AI tools to automatically schedule meetings and appointments, but they’ll be there to oversee your calendar, resolve conflicts, and ensure everything runs smoothly. You get a seamless, conflict-free schedule managed by a real person.',
            productImage: '/assets/product4.png'   
        },
        {
            category: 'MARKETING COORDINATION',
            title: 'Coordinate Campaigns and Materials to Enhance Outreach',
            description: 'Our human assistant will use AI to streamline your marketing efforts, but they’ll handle the strategy, customization, and oversight of each campaign. From scheduling posts to creating engaging content, your assistant will ensure your marketing is on target and on time.',
            productImage: '/assets/product5.png'   
        },
        {
            category: 'STRATEGIC PLANNING',
            title: 'Develop Strategies to Grow Your Business',
            description: 'Our AI tools provide valuable insights, but it’s your human assistant who will collaborate with you to create and refine strategies that align with your business goals. Together, you’ll unlock new growth opportunities and optimize your operations.',
            productImage: '/assets/product6.png'   
        },
        {
            category: 'MISCELLANEOUS TASKS',
            title: 'Flexible Support for Ad-Hoc Needs',
            description: 'Whether you need help with a one-off project or recurring administrative tasks, our human assistants use AI tools to get the job done faster, while providing you with the flexibility and support to handle any ad-hoc needs that come your way.',
            productImage: '/assets/product7.png'   
        }
    ]

    return (
        <div className="px-4 pt-[60px] pb-0 md:pb-[60px] flex flex-col justify-center items-center">
                <div className="lg:w-[840px] text-center"><TextDisplayMedium color={'lightOnSurface'} text={'ReBillion AI: Where Human Expertise Meets the Power of Technology for Real Estate Professionals'}/></div>

                <div className="mt-[40px] lg:mt-[60px] text-center"><TextHeadlineMedium color={'lightOnSurface'} text={'What We Offer - AI Trained Virtual Assistants'} /> </div>
                <div className="mt-[40px] text-center"><TextHeadlineMedium color={'lightOnSurface'} text={'Our Assistants helps in'} /> </div>

                <div className="mt-[40px]">
                    {
                        productsArray.map(product=>(
                            <div className="flex flex-col xl:flex-row xl:items-center mb-20 lg:gap-x-10">
                                <div className="md:w-[540px]">
                                    <div className="mb-[12px]"><TextTitleMedium text={product.category} color={'lighOnSurfaceVariant'} /></div>
                                    <div className="mb-[12px]"><TextHeadlineMedium text={product.title} color={'lightOnSurface'} /></div>
                                    <div><TextBodyLarge text={product.description} color={'lighOnSurfaceVariant'}/></div>
                                </div>
                                <div className="mt-[32px] xl:mt-0">
                                    <img
                                        className="w-full lg:h-[363px] lg:min-w-[653px] flex-1 relative rounded-[15px] max-w-full overflow-hidden mq750:min-w-full"
                                        loading="lazy"
                                        alt=""
                                        src={product.productImage}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
        </div>
    )
};


export default Products