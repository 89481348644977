import { 
    TextDisplayLarge, 
    TextLabelLarge 
} from "../common/typography"

const LetsStart = ({scrollToTarget}) => {
    return (
        <div className="py-[60px] px-4 lg:py-20 bg-[url('/gradient-14-1.svg')] bg-center bg-no-repeat bg-cover w-full flex flex-col items-center justify-center">
            <div className="mb-[60px] text-center">
                <TextDisplayLarge color='lightPrimary' text='Let’s transform your real estate ' /> <br />
                <TextDisplayLarge color='lightPrimary' text='workflow' />
            </div>
            <button className="bg-lightPrimary py-3 px-6 rounded-md text-lightOnPrimary" onClick={scrollToTarget}>
                <TextLabelLarge color='lightOnPrimary' text='Get started' />
            </button>
        </div>
    )
}

export default LetsStart