import { 
    TextBodyXLarge, 
    TextDisplayMedium 
} from "../common/typography"

const Benefits = () => {

    let benefits = [
        {
            title: '5X',
            description:'AI-Powered Efficiency with Human Expertise'
        },
        {
            title: '3X',
            description:'Customizable Solutions for Your Business'
        },
        {
            title: '40%',
            description:'Time & Cost Savings'
        }
    ]

    return (
        <div className="px-4 py-[60px] md:py-20 flex flex-col items-center">
            <div className="lg:w-[840px] text-center">
                <TextDisplayMedium color={'lightOnSurface'} text={'Go from contract to close faster and smarter with Rebillion'} />
            </div>
            <div className="w-full mt-[40px] lg:mt-[60px] lg:px-[108px] flex flex-col justify-center items-center md:flex-row justify-between gap-y-[40px]">
                {
                    benefits.map(benefit=>(
                        <div className="w-[306.3px] text-center flex flex-col items-center justify-start gap-[8px]">
                            <div className='text-[54px] lg:text-[72px] font-bold leading-[64px] lg:leading-[87.14px] text-lightPrimary'>
                                {benefit.title}
                            </div>
                            <div>
                                <TextBodyXLarge text={benefit.description} color={'lighOnSurfaceVariant'}/>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Benefits