
import PropTypes from "prop-types";
import CalendlyWidget from "../common/calendlyWidget";
import { 
  TextDisplayMedium, 
  TextLabelLarge 
} from "../common/typography";

const BookDemo = ({targetRef }) => {
  return (
    <div
      className={`w-full bg-[#edf4f3] py-[60px] lg:py-20 px-4 lg:px-[108px] gap-x[60px] flex flex-col lg:flex-row justify-between items-start gap-y-10`}
      ref={targetRef}
    > 
      <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start mt-16 text-center lg:text-start">
        <TextDisplayMedium color={'lightOnSurface'} text={'Book a demo today and unlock faster, smarter real estate workflow'}/> 
        <button className="mt-8 bg-lightPrimary py-3 px-6 rounded-md text-lightOnPrimary" onClick={()=>window.open('https://tc.rebillion.ai/contact/')}>
            <TextLabelLarge color='lightOnPrimary' text='Contact Us' />
        </button>
      </div>
      <div className="w-full lg:w-1/2">
        <CalendlyWidget/>
      </div>
    </div>
  );
};

BookDemo.propTypes = {
  className: PropTypes.string,
};

export default BookDemo;
